<template>
    <el-container v-if="info.id">
        <el-main class="left">
            <div class="order-info">
                <div class="title">订单信息</div>
                <div class="content">
                    <ul class="order-num">
                        <li>
                            退款单号：
                            <span>{{ info.refund_sn }}</span>
                        </li>
                        <li>
                            订单编号：
                            <span>{{ info.order_sn }}</span>
                        </li>
                        <li>
                            订单来源：
                            <span>{{ info.from == 1 ? '微信' : info.from == 2 ? '支付宝' : '微信小程序' }}</span>
                        </li>
                    </ul>
                    <div class="handle-progress">
                        <template v-if="info.refund_type == 1">
                            <div class="item" style="background: #ddddfd">
                                <div class="num" style="background: #409eff; color: #fff">1</div>
                                <p class="handle-text" style="color: #409eff">提交申请</p>
                                <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item"
                                :style="{ background: status == 7 || status == 8 || status == 6 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status == 7 || status == 8 || status == 6 ? '#409EFF' : '', color: status == 7 || status == 8 || status == 6 ? '#fff' : '' }">
                                    2</div>
                                <p class="handle-text"
                                    :style="{ color: status == 7 || status == 8 || status == 6 ? '#409EFF' : '' }">商家审核</p>
                                <p class="handle-time" v-if="info.handle_time">{{ getDateformat(info.handle_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item"
                                :style="{ background: status == 7 || status == 8 || status == 6 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status == 7 || status == 8 || status == 6 ? '#409EFF' : '', color: status == 7 || status == 8 || status == 6 ? '#fff' : '' }">
                                    3</div>
                                <p class="handle-text"
                                    :style="{ color: status == 7 || status == 8 || status == 6 ? '#409EFF' : '' }">售后完成</p>
                                <p class="handle-time" v-if="info.success_time">{{ getDateformat(info.success_time) }}</p>
                            </div>
                        </template>
                        <template v-if="info.refund_type == 2">
                            <div class="item" style="background: #ddddfd">
                                <div class="num" style="background: #409eff; color: #fff">1</div>
                                <p class="handle-text" style="color: #409eff">提交申请</p>
                                <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item" :style="{ background: status > 1 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status > 1 ? '#409EFF' : '', color: status > 1 ? '#fff' : '' }">2
                                </div>
                                <p class="handle-text" :style="{ color: status > 1 ? '#409EFF' : '' }">商家审核</p>
                                <p class="handle-time" v-if="info.handle_time">{{ getDateformat(info.handle_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item" :style="{ background: status > 2 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status > 2 ? '#409EFF' : '', color: status > 2 ? '#fff' : '' }">3
                                </div>
                                <p class="handle-text" :style="{ color: status > 2 ? '#409EFF' : '' }">买家退货</p>
                                <p class="handle-time" v-if="info.tracking_time">{{ getDateformat(info.tracking_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item" :style="{ background: status > 3 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status > 3 ? '#409EFF' : '', color: status > 3 ? '#fff' : '' }">4
                                </div>
                                <p class="handle-text" :style="{ color: status > 3 ? '#409EFF' : '' }">商家收货</p>
                                <p class="handle-time" v-if="info.receive_time">{{ getDateformat(info.receive_time) }}</p>
                            </div>
                            <div class="line"></div>
                            <div class="item" :style="{ background: status >= 4 ? '#ddddfd' : '' }">
                                <div class="num"
                                    :style="{ background: status >= 4 ? '#409EFF' : '', color: status >= 4 ? '#fff' : '' }">
                                    5</div>
                                <p class="handle-text" :style="{ color: status >= 4 ? '#409EFF' : '' }">售后完成</p>
                                <p class="handle-time" v-if="info.success_time">{{ getDateformat(info.success_time) }}</p>
                            </div>
                        </template>
                    </div>
                    <div class="handle-status">
                        <div class="status">
                            订单状态：{{
                                status == 9
                                ? '等待商家确认补打款'
                                : status == 1
                                    ? '等待商家处理退款申请'
                                    : status == 5 || status == 7 || status == 4
                                        ? '退款成功'
                                        : status == 8
                                            ? '退款申请驳回'
                                            : status == 2
                                                ? '等待买家发货'
                                                : status == 3
                                                    ? '等待商家确认收货并退款'
                                                    : '买家取消退款'
                            }}
                        </div>
                        <p v-if="info.refund_type == 1">
                            {{
                                status == 9
                                ? '待补打款'
                                : status == 1
                                    ? '收到买家退款申请，仅退款不退货，请尽快处理'
                                    : status == 7
                                        ? '同意买家退款，并退款成功'
                                        : status == 8
                                            ? '商家驳回买家退款申请'
                                            : '买家已经取消退款，退款功能关闭'
                            }}
                        </p>
                        <p v-else>
                            {{
                                status == 9
                                ? '买家已退货商品，请商家确认补打款'
                                : status == 1
                                    ? '收到买家退货退款申请，需要发送买家退货地址，请尽快处理'
                                    : status == 2
                                        ? '商家同意买家退货，等待买家上传退货快递信息'
                                        : status == 3
                                            ? '买家已退货商品，请商家确认，并点击确认收货并退款'
                                            : status == 5 || status == 4
                                                ? '同意买家退货退款，并退款成功'
                                                : status == 8
                                                    ? '商家驳回买家退货退款申请'
                                                    : '买家已经取消退货退款，退货退款功能关闭'
                            }}
                        </p>
                        <div class="btn" v-if="info.refund_type == 1 && status != 6 && status != 8">
                            <el-button type="primary" v-show="status == 1 || status == 9"
                                @click="handleSales(20)">同意退款</el-button>
                            <el-button v-show="status == 1" @click="handleSales(10)">驳回申请</el-button>
                        </div>
                        <div class="btn" v-if="(status == 1 || status == 3 || status == 9) && info.refund_type == 2">
                            <el-button type="primary" @click="handleSales(20)">{{ status == 1 ? '同意退货，发退货地址' : status == 3 ?
                                '确认收货并退款' : '' }}</el-button>
                            <el-button @click="handleSales(10)">驳回申请</el-button>
                        </div>
                    </div>
                </div>
            </div>
            -
            <div class="business-handle">
                <div class="title">商家处理</div>
                <div class="content">
                    <div class="handle-methods">
                        <p>
                            处理方式：
                            <span>{{ info.state_desc }}</span>
                        </p>
                        <p>
                            退款金额：
                            <span>￥{{ info.refund_amount }}</span>
                        </p>
                        <p>
                            处理人：
                            <span>{{ info.handler_name || '--' }}</span>
                        </p>
                    </div>
                    <div class="log">
                        商家备注：
                        <span>{{ info.handle_message || '--' }}</span>
                    </div>
                    <div class="address">
                        退货地址：
                        <span>{{ info.shipper_info ? info.shipper_info.combine_detail + info.shipper_info.address : '- -'
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="buyers-info">
                <div class="title">买家信息</div>
                <div class="content">
                    <div>
                        <p>
                            买家：
                            <span>{{ info.user_name || '--' }}</span>
                        </p>
                        <p>
                            买家备注：
                            <span>{{ info.message || '--' }}</span>
                        </p>
                        <p>商家备注：{{ info.system_message || '--' }}</p>
                    </div>
                    <div>
                        <p>
                            收件人：
                            <span>{{ info.order_info.extend_order_extend.reciver_infos ?
                                info.order_info.extend_order_extend.reciver_infos.name
                                : info.order_info.extend_order_extend.delivery_infos ?
                                    info.order_info.extend_order_extend.delivery_infos.name
                                    : info.user_name }}</span>
                        </p>
                        <p>
                            收件人电话：
                            <span>{{ info.order_info.extend_order_extend.reciver_infos ?
                                info.order_info.extend_order_extend.reciver_infos.phone
                                : info.order_info.extend_order_extend.delivery_infos ?
                                    info.order_info.extend_order_extend.delivery_infos.mobile : info.user_phone }}</span>
                        </p>
                        <!--<p>
                            配送方式：
                            <span> {{ order_type.find(v => v.val == info.order_type).label || '' }}</span>
                        </p>-->
                    </div>
                    <div>
                        <p v-if="info.header_id && info.consignee_time != '0'">
                            预计送达时间：
                            <span>{{ info.consignee_time }}</span>
                        </p>
                        <p>
                            收件人地址：
                            <span>{{
                                info.order_info.extend_order_extend.reciver_infos ?
                                info.order_info.extend_order_extend.reciver_infos.address :
                                info.order_info.extend_order_extend.delivery_infos
                                    ?
                                    (info.order_info.extend_order_extend.community_info.address +
                                        info.order_info.extend_order_extend.delivery_infos.address) : '--' }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="goods-info">
                <div class="title">商品信息</div>
                <div class="content">
                    <el-table :data="goods_info" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                        <el-table-column prop="goods_img" label="商品信息" align="center">
                            <template slot-scope="scope">
                                <div class="goods-title">
                                    <el-image :src="scope.row.goods_img"></el-image>
                                    <p>{{ scope.row.goods_title }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="spec_title" label="规格" align="center"></el-table-column>
                        <el-table-column prop="goods_price" label="单价" align="center"></el-table-column>
                        <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
                        <el-table-column prop="goods_pay_price" label="小计" align="center">
                            <template slot-scope="scope">
                                {{ (scope.row.goods_price * scope.row.goods_num).toFixed(2) }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="price">
                        <p>
                            <span>商品总价：</span>
                            <span>￥{{ info.order_info.goods_amount }}</span>
                        </p>
                        <p>
                            <span>运费：</span>
                            <span>￥{{ info.order_info.freight_fee }}</span>
                        </p>
                        <p v-if="Number(info.reduction_amount) > 0">
                            <span>优惠券立减：</span>
                            <span>￥{{ info.reduction_amount }}</span>
                        </p>
                    </div>
                    <div class="total-price">
                        <span>实付：</span>
                        <span>￥{{ Number(info.order_info.revise_amount) > 0 ?
                            Number(info.order_info.revise_amount).toFixed(2) :
                            Number(info.order_info.amount).toFixed(2)
                        }}</span>
                    </div>
                </div>
            </div>
        </el-main>
        <div class="right">
            <div class="title r-title">维权信息</div>
            <div class="dimension-info">
                <div class="info">
                    <el-image :src="info.goods_img"></el-image>
                    <p>{{ info.goods_title }}</p>
                </div>
                <div class="apply-info">
                    <div class="title">买家申请</div>
                    <p>
                        <span>申请时间：</span>
                        <span>{{ getDateformat(info.create_time) }}</span>
                    </p>
                    <p>
                        <span>处理方式：</span>
                        <span>{{ info.refund_type == 1 ? '仅退款' : '退货退款' }}</span>
                    </p>
                    <p>
                        <span>退款金额：</span>
                        <span>￥{{ info.refund_amount }}</span>
                        <span></span>
                    </p>
                    <p>
                        <span>退款原因：{{ info.refund_reason }}</span>
                        <span></span>
                    </p>
                    <p>
                        <span>退款说明：</span>
                        <span>{{ info.user_reason }}</span>
                    </p>
                    <div class="img" v-if="info.user_images.length">
                        <span>图片凭证：</span>
                        <div>
                            <span></span>
                            <el-image :preview-src-list="[item]" :src="item" v-for="(item, index) in info.user_images"
                                :key="index"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 8px; background: #f0f0f5"></div>
            <el-main class="logistics-address">
                <div class="title">物流信息</div>
                <template v-if="info.logisticsQuery">
                    <div class="sn">{{ info.tracking_company }}：{{ info.logisticsQuery.nu }}</div>
                    <el-timeline :reverse="reverse">
                        <el-timeline-item v-for="(item, index) in info.logisticsQuery.data" :key="index"
                            :timestamp="item.time">
                            {{ item.context }}
                        </el-timeline-item>
                    </el-timeline>
                </template>
                <template v-else>
                    <No />
                </template>
            </el-main>
        </div>
        <el-dialog title="退款审核" :visible.sync="showHandleSales" width="35%">
            <el-form label-width="90px">
                <el-form-item label="处理方式：">{{ info.refund_type == 1 ? '退款（仅退款不退货）' : '退款（退货退款）' }}</el-form-item>
                <el-form-item label="退款金额：">{{ info.refund_amount }}</el-form-item>
                <el-form-item label="处理结果：">
                    <el-radio-group v-model="handle_state">
                        <el-radio :label="20">同意退款</el-radio>
                        <el-radio :label="10" v-show="status != 9">驳回申请</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退货地址：" v-if="info.refund_type == 2 && handle_state == 20">
                    <el-select v-model="shipper_id" filterable placeholder="请选择">
                        <el-option v-for="item in addressList" :key="item.id" :label="item.combine_detail + item.address"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="handle_state == 10" label="驳回原因：" class="message">
                    <el-input v-model="handle_message" type="textarea" :rows="8"
                        placeholder="请添加驳回原因，不超过100字，说明会显示在用户订单详情的驳回原因中"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showHandleSales = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmHandleSales">确 定</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>
  
<script>
import { getDateformat } from '@/util/getDate';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
export default {
    data () {
        return {
            id: '',
            status: '', //1： 等待退款处理 2：同意申请，等待买家退货  3：买家已发货，等待收货 4:已收货，确认退款 5:退款成功 6:退款关闭 7同意退款，仅退款 8:拒绝(驳回)
            info: {},
            showHandleSales: !1,
            handle_state: 20,
            handle_message: '',
            goods_info: [],
            addressList: [],
            shipper_id: '',
            order_type: [
                {
                    val: 3,
                    label: '快递物流',
                },
                {
                    val: 4,
                    label: '商家自送',
                },
                {
                    val: 5,
                    label: '自提',
                }
            ],
        };
    },
    watch: {
        showHandleSales (val) {
            if (val && !this.addressList.length && this.info.refund_type == 2) {
                this.$axios.post(this.$api.set.address, { rows: 10000 }).then(res => {
                    if (res.code == 0) {
                        this.addressList = res.result.list;
                    }
                });
            }
        },
    },
    created () {
        this.id = this.$route.query.id;
        this.status = this.$route.query.status;
        let utils = this.$store.state.utils;
        utils.page = Number(this.$route.query.page);
        utils.rows = Number(this.$route.query.rows);
        utils.is_record = !0;
        this.getRefundInfo();
    },
    methods: {
        //处理仅退款&退货退款发送地址
        comfirmHandleSales () {
            let data = {
                id: this.id,
                handle_state: this.handle_state,
            };
            if (this.handle_state == 10) {
                data.handle_message = this.handle_message;
                if (!data.handle_message)
                    return this.$message({
                        message: '请填写驳回原因',
                        type: 'warning',
                    });
            }
            if (this.info.refund_type == 2 && this.handle_state == 20) {
                if (!this.shipper_id)
                    return this.$message({
                        message: '请选择退货地址',
                        type: 'warning',
                    });
                data.shipper_id = this.shipper_id;
            }
            if (this.handle_state == 10) {
                this.$axios.post(industryName + this.$api.order.handleRefund, data).then(res => {
                    if (res.code == 0) {
                        this.getRefundInfo();
                        this.showHandleSales = !1;
                        this.$message.success('处理成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else if (this.info.is_close == 0 && this.info.handle_time > 0) {
                this.$axios.post(industryName + this.$api.order.refund, { id: this.id }).then(res => {
                    if (res.code == 0) {
                        this.getRefundInfo();
                        this.showHandleSales = !1;
                        this.$message({
                            message: '退款成功',
                            type: 'success',
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$axios
                    .post(industryName + this.$api.order.handleRefund, data)
                    .then(res => {
                        if (res.code == 0) {
                            if (this.info.refund_type == 2) {
                                this.getRefundInfo();
                                this.showHandleSales = !1;
                                this.$message.success('处理成功');
                            } else {
                                //调用退款
                                return this.$axios.post(industryName + this.$api.order.refund, { id: this.id });
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.getRefundInfo();
                            this.showHandleSales = !1;
                            this.$message({
                                message: '退款成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }
        },
        //处理退货退款
        handleSales (val) {
            if (this.status == 3) {
                //商家收货
                this.$confirm(`请确认是否收货并退款？`, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                }).then(() => {
                    this.$axios
                        .post(industryName + this.$api.order.receive, { id: this.id })
                        .then(res => {
                            if (res.code == 0) {
                                return this.$axios.post(industryName + this.$api.order.refund, { id: this.id });
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.getRefundInfo();
                                this.$message({
                                    message: '已确认收货并退款',
                                    type: 'success',
                                });
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                });
            } else {
                this.handle_state = val;
                this.showHandleSales = !0;
            }
        },
        //获取售后信息
        getRefundInfo () {
            this.$axios.post(industryName + this.$api.order.refundInfo, { id: this.id }).then(res => {
                if (res.code == 0) {
                    let info = res.result.info;
                    let handle_state = info.handle_state;
                    let handleState = '';
                    let order_sn = info.order_sn.split('');
                    let refund_sn = info.refund_sn.split('');
                    //info.reciver_info.combine_detail = info.reciver_info.combine_detail.replace(/\s*/g, '');
                    if (Number(info.goods_idscount_num) > 0) {
                        info.actual_price = Number(info.goods_pay_price) * Number(info.goods_idscount_num);
                        info.actual_price = info.actual_price.toFixed(2);
                    } else {
                        info.actual_price = info.goods_pay_price;
                    }
                    for (let i = 4; i < order_sn.length; i += 4) {
                        order_sn[i - 1] = order_sn[i - 1] + ' ';
                    }
                    for (let i = 4; i < refund_sn.length; i += 4) {
                        refund_sn[i - 1] = refund_sn[i - 1] + ' ';
                    }
                    info.order_sn = order_sn.join('');
                    info.refund_sn = refund_sn.join('');
                    if (info.user_images) info.user_images = JSON.parse(info.user_images);
                    if (handle_state == 0) {
                        handleState = 1;
                    }
                    if (handle_state == 20 && info.refund_type == 2 && !info.tracking_no && !info.tracking_time) {
                        handleState = 2;
                    }
                    if (handle_state == 20 && info.refund_type == 2 && info.tracking_no != null && info.tracking_time > 0 && info.receive == 1) {
                        handleState = 3;
                    }
                    if (handle_state == 20 && info.refund_type == 2 && info.tracking_no != null && info.tracking_time > 0 && info.receive == 2) {
                        handleState = 4;
                    }
                    if (handle_state == 30) {
                        handleState = 5;
                    }
                    if (info.is_close == 1 && (handle_state == 50 || handle_state == 51)) {
                        handleState = 6;
                    }
                    if (handle_state == 20 && info.refund_type == 1) {
                        handleState = 7;
                    }
                    if (handle_state == 10) {
                        handleState = 8;
                    }
                    if (info.refund_type == 1) {
                        if (info.is_close == 0 && handle_state >= 20 && info.handle_time > 0) handleState = 9;
                    } else {
                        if (info.is_close == 0 && handle_state >= 20 && info.handle_time > 0 && !info.tracking_no && !info.tracking_time && info.receive == 2) handleState = 9;
                    }
                    this.status = handleState;
                    this.goods_info = [];
                    this.goods_info.push({
                        goods_img: info.goods_img,
                        goods_num: info.goods_num,
                        goods_title: info.goods_title,
                        spec_title: info.goods_spec?.title || '--',
                        goods_price: info.goods_price,
                        goods_pay_price: info.goods_pay_price,
                    });
                    this.info = info;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-container {
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #17171a;
}

.title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 20px;
}

.left {
    margin-right: 8px;
    background: #fff;

    .order-info {
        margin-bottom: 30px;
    }

    .order-info .content {
        .order-num {
            display: flex;
            height: 47px;
            background: #f0f0f5;
            align-items: center;
            padding-left: 24px;

            li {
                font-size: 14px;
                margin-right: 40px;

                span {
                    font-weight: 400;
                    color: #17171a;
                }
            }
        }

        .handle-progress {
            display: flex;
            justify-content: center;
            height: 190px;
            align-items: center;

            .line {
                height: 4px;
                width: 120px;
                background: #f0f0f5;
            }

            .item {
                background: #fff;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .num {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: #c8c8cc;
                    border-radius: 50%;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: bold;
                }

                .handle-text {
                    width: 100px;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -25px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #969699;
                }

                .handle-time {
                    width: 120px;
                    position: absolute;
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                    bottom: -25px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #969699;
                }
            }
        }

        .handle-status {
            padding: 30px 0;
            background: #f0f0f5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .status {
                font-size: 16px;
                font-weight: bold;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #969699;
                margin-top: 18px;
            }

            .btn {
                display: flex;
                align-items: center;
                margin-top: 20px;
            }
        }
    }

    .business-handle {
        margin-bottom: 30px;

        .content {
            margin-top: 24px;
            padding-left: 40px;

            div {
                span {
                    font-weight: 400;
                }
            }

            .handle-methods {
                display: flex;
                margin-bottom: 8px;

                p {
                    margin-right: 40px;

                    &:nth-child(2) {
                        span {
                            color: #fd5e94;
                        }
                    }
                }
            }

            .log {
                margin-bottom: 8px;
            }
        }
    }

    .buyers-info {
        margin-bottom: 30px;

        .content {
            margin-top: 24px;
            padding-left: 40px;

            div {
                font-weight: bold;
                display: flex;

                span {
                    font-weight: 400;
                }

                p {
                    width: 240px;

                    &:last-child {
                        width: auto;
                    }
                }

                &:nth-child(1) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .goods-info .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            margin-top: 20px;
            border-bottom: 1px solid #f5f5fa;
            color: #969699;

            span:last-child {
                color: #17171a;
                width: 80px;
                text-align: right;
            }

            p {
                display: flex;
                margin-bottom: 5px;
            }
        }

        .goods-title {
            display: flex;
            justify-content: center;
            align-items: center;

            .el-image {
                width: 68px;
                height: 68px;
                border-radius: 8px;
                margin-right: 8px;
            }

            p {
                width: 140px;
            }
        }

        .total-price {
            font-weight: bold;
            margin-top: 5px;
            display: flex;

            span:last-child {
                text-align: right;
                font-weight: 400;
                color: #fd5e94;
                width: 80px;
            }
        }
    }
}

.right {
    width: 480px;
    background: #fff;

    .r-title {
        margin: 20px 0 0 20px;
    }

    .dimension-info {
        padding: 20px;

        .info {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .el-image {
                width: 68px;
                height: 68px;
                border-radius: 8px;
                margin-right: 16px;
            }

            p {
                flex: 1;
            }
        }

        .apply-info {
            p {
                margin-bottom: 8px;
            }

            p:nth-child(3) {
                span:last-child {
                    color: #5654f3;
                }
            }

            p:nth-child(4) {
                span:last-child {
                    color: #fd5e94;
                }
            }

            .img {
                width: 100%;

                div {
                    margin-top: 8px;

                    img {
                        width: 68px;
                        height: 68px;
                        border-radius: 8px;
                        margin-right: 16px;
                    }

                    .el-image {
                        width: 68px;
                        height: 68px;
                        border-radius: 8px;
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    .logistics-address {
        width: 480px;
        background: #fff;

        .sn {
            margin-bottom: 30px;
        }

        /deep/ .el-timeline-item__content {
            color: #17171a;
        }
    }
}
</style>
  